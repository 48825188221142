import { motion } from 'framer-motion'

import { HiOutlineLocationMarker, HiOutlinePhone } from 'react-icons/hi'
import { MdOutlineAlternateEmail } from 'react-icons/md'
import './Contact.scss'

const Contact = () => {

  return (
    <section className="section contact" id="contact">
      <h2 className="section__title">Me contacter</h2>
      <span className="section__subtitle">Fabien Chareun</span>

      <div className="contact__container">
        <div className="contact__info">
          <motion.div
            whileInView={{ y: [50, 0], opacity: [0, 1] }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="contact__info-item"
          >
            <HiOutlinePhone />
            <div>
              <h3 className="contact__title">Téléphone</h3>
              <span className="contact__subtitle">+33 6 21 71 16 70</span>
            </div>
          </motion.div>
          <motion.div
            whileInView={{ y: [50, 0], opacity: [0, 1] }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="contact__info-item"
          >
            <MdOutlineAlternateEmail />
            <div>
              <h3 className="contact__title">Email</h3>
              <span className="contact__subtitle">fabienchareun@gmail.com</span>
            </div>
          </motion.div>
          <motion.div
            whileInView={{ y: [50, 0], opacity: [0, 1] }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="contact__info-item"
          >
            <HiOutlineLocationMarker />
            <div>
              <h3 className="contact__title">Localisation</h3>
              <span className="contact__subtitle">
                Saint Etienne 42000
              </span>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export default Contact
